<template>
  <div class="page-container role-list">
    <div class="page-header">
      <ace-header show-back>
        {{ $t('system.charge.chooseRole') }}
        <template v-if="roleList.length < 20" #right>
          <router-link to="/role-add">
            <van-icon name="plus" size="24" color="#efefef" />
          </router-link>
        </template>
      </ace-header>
    </div>
    <div class="page-body pt-10px px-10px">
      <van-list
        v-model="loading"
        :loading-text="$t('common.loadingText')"
        :finished="finished"
        @load="getRoleData"
      >
        <template v-if="roleList.length">
          <role-item
            v-for="item in roleList"
            :key="item.id"
            :info="item"
            class="mb-10px"
            @on-delete="onDelete"
            @on-update="onUpdate"
            @on-select="onSelect"
          />
        </template>
        <div v-if="!loading && !roleList.length" class="mt-100px">
          <van-empty image="search" :description="$t('system.exception.noDataTitle')" />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { storage } from '@/utils/storage';
  import { getParam } from '@/utils/assist';
  import { getRoleList, deleteRole, updateRole } from '@/api/system';
  import RoleItem from '@/components/RoleItem.vue';
  export default {
    name: 'RoleList',
    components: { RoleItem },
    data() {
      return {
        loading: false,
        finished: false,
        roleList: [],
        selectedRoleId: '',
        isUpdateDisabled: false,
      };
    },
    computed: {
      ...mapGetters({
        defaultGame: 'defaultGame',
      }),
    },
    methods: {
      async getRoleData() {
        this.loading = true;
        try {
          const app_uid = getParam('gid') || this.defaultGame.app_uid;
          const res = await getRoleList(Number(app_uid));
          this.roleList = res;
        } finally {
          this.finished = true;
          this.loading = false;
        }
      },
      onDelete(id) {
        const that = this;
        this.$dialog.confirm({
          message: this.$t('system.charge.confirmDel'),
          beforeClose: async function (action, done) {
            if (action === 'confirm') {
              try {
                const res = await deleteRole(that.defaultGame.app_uid, id);
                if (res) {
                  await that.getRoleData();
                }
              } finally {
                done();
              }
            } else {
              done();
            }
          },
        });
      },
      async onUpdate(id) {
        if (this.isUpdateDisabled) return;
        try {
          const res = await updateRole(this.defaultGame.app_uid, id);
          this.isUpdateDisabled = true;
          if (res) {
            this.$toast(this.$t('system.api.operationSucceed'));
            this.getRoleData();
          }
        } finally {
          this.isUpdateDisabled = false;
        }
      },
      onSelect(data) {
        storage.set('SELECTED_ROLE', data);
        this.$router.replace({ name: 'order' });
      },
    },
  };
</script>
